import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { numberToMoney } from '../../helpers';
import BorderLinearProgress from './BorderLinearProgress';
import CurrencyFlag from '../countries/CurrencyFlag';
import CreditLineTooltip from '../tooltips/CreditLineTooltip';

const CreditLineLinearProgress = ({ creditLine, width }) => {
  if (creditLine.id.startsWith('missing-')) {
    return (
      <Stack direction="row" spacing={2} alignItems="center" sx={{ width: width || '50%' }}>
        <Stack spacing={0.5} sx={{ flex: 1 }}>
          <Typography align="center">Empresa sin línea de crédito</Typography>
        </Stack>
        <CurrencyFlag isoCode={creditLine.currency.isoCode} />
      </Stack>
    );
  }

  const rate = creditLine && (creditLine?.creditUsed || creditLine?.limitAmount)
    ? creditLine.creditUsed / creditLine.limitAmount
    : 0;
  const value = Math.min(rate, 1);

  const currency = creditLine?.currency?.isoCode;
  const creditUsed = numberToMoney(creditLine?.creditUsed, currency);
  const lineAmount = numberToMoney(creditLine?.limitAmount, currency);

  const availableAmount = Math.max(creditLine.limitAmount - creditLine.creditUsed, 0);
  const totalAmount = numberToMoney(availableAmount, currency);

  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ width: width || '50%' }}>
      <Stack spacing={0.5} sx={{ flex: 1 }}>
        <Tooltip
          title={(
            <CreditLineTooltip
              totalCredit={lineAmount}
              creditUsed={creditUsed}
              available={totalAmount}
              isoCode={currency}
            />
          )}
          componentsProps={{
            tooltip: {
              sx: {
                borderRadius: '8px',
                bgcolor: 'grey.900',
                '& .MuiTooltip-arrow': {
                  color: 'grey.900',
                },
              },
            },
          }}
          arrow
        >
          <BorderLinearProgress variant="determinate" value={value * 100} />
        </Tooltip>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography noWrap sx={{ fontSize: 10 }}>Utilizado</Typography>
            <Typography noWrap sx={{ fontSize: 10 }}>{creditUsed}</Typography>
          </Stack>
          <Stack>
            <Typography noWrap sx={{ fontSize: 10 }}>Disponible</Typography>
            <Typography noWrap sx={{ fontSize: 10 }}>{totalAmount}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <CurrencyFlag isoCode={currency} />
    </Stack>
  );
};

CreditLineLinearProgress.propTypes = {
  creditLine: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creditUsed: PropTypes.number,
    limitAmount: PropTypes.number,
    currency: PropTypes.shape({
      isoCode: PropTypes.string.isRequired,
    }),
  }),
  width: PropTypes.string,
};

CreditLineLinearProgress.defaultProps = {
  creditLine: {
    id: 'missing-credit-line',
    creditUsed: 0,
    limitAmount: 0,
    currency: { isoCode: 'CLP' },
  },
  width: '50%',
};

export default CreditLineLinearProgress;
