import Remove from '@mui/icons-material/Remove';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  activeStyle: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const SubRoutesList = ({ closeDrawer, routePath, subRoutes }) => {
  const location = useLocation();
  const classes = useStyles();

  const isSubRouteActive = useCallback(
    (subRoute, index) => {
      if (location.pathname.includes(subRoute)) return true;
      if (subRoute.includes(location.pathname) && index === 0) return true;
      return false;
    },
    [location],
  );

  return (
    <List>
      {subRoutes
        .filter(({ show }) => !!show)
        .map(({ id, label, path, disabled }, index) => (
          <ListItemButton
            id={`mobile-${id}`}
            key={id}
            disabled={disabled}
            component={NavLink}
            to={`${routePath}/${path}`}
            activeClassName={classes.activeStyle}
            isActive={() => isSubRouteActive(`${routePath}/${path}`, index)}
            onClick={closeDrawer}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
              <Remove color="common.white" sx={{ opacity: 0 }} />
            </ListItemIcon>
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                color: 'white',
                variant: 'body1',
                fontWeight: '300',
              }}
            />
          </ListItemButton>
        ))}
    </List>
  );
};

SubRoutesList.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  routePath: PropTypes.string.isRequired,
  subRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.element,
      component: PropTypes.element,
      disabled: PropTypes.bool.isRequired,
      show: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default SubRoutesList;
